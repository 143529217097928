import React from 'react';
import './App.css';
import Game from './Game';
import Setup from './Setup';

interface AppProps { }

interface AppState {
  started: boolean;
  rounds: string[][];
  teams: string[][];
}

const defaultRounds = [[
  "LAST",
  "STAND",
  "BACK",
  "DRAFT",
  "PICK",
  "APART"
],
[
  "GET",
  "LOST",
  "WORLD",
  "POWER",
  "LINE",
  "DANCE"
],
[
  "FRENCH",
  "PRESS",
  "CONFERENCE",
  "CALL",
  "OFF",
  "RAMP"
], [
  "RIVER",
  "BEND",
  "OVER",
  "BUDGET",
  "CUT",
  "ABOVE",
  "BOARD"
]
];


class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = { started: false, rounds: defaultRounds, teams: [[],[]] };

    this.start = this.start.bind(this);
  }

  start(rounds: string[][], teams: string[][]) {
    this.setState({ started: true, rounds, teams });
  }

  render() {
    return (
      <div className="App">
        {this.state.started &&
          <Game rounds={this.state.rounds} teams={this.state.teams}></Game>
        }
        {!this.state.started &&
          <Setup rounds={defaultRounds} startGame={this.start}></Setup>
        }
      </div>
    );
  }
}

export default App;
