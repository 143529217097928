import React from 'react';
import './Link.css';

interface LinkProps {
  word: string;
  lettersRevealed: number;
  active: boolean;
}

class Link extends React.Component<LinkProps> {
  render() {
    let wordWithPadding = this.props.word.padEnd(11, ' ')
    const letters = Array.from(wordWithPadding).map((letter, index) => {
      const letterToRender = index < this.props.lettersRevealed ? letter : ' ';
      return (
        <div className='Link-letter'>{letterToRender}</div>
      );
    });

    const classNames = ["Link"];
    if (this.props.active) {
      classNames.push("active");
    }

    return (
      <div className={classNames.join(" ")}>
        {letters}
      </div>
    );
  }
}

export default Link;