import React from 'react';
import './Chain.css';
import Link from './Link';

interface ChainProps {
  words: string[];
  topWordIndex: number;
  botWordIndex: number;
  topLetterIndex: number;
  botLetterIndex: number;
  activeIndex?: number;
}

function Chain(props: ChainProps) {
  const links = props.words.map((word, index) => {
    let lettersRevealed = 0;
    if (index < props.topWordIndex || index > props.botWordIndex) {
      lettersRevealed = word.length;
    } else if (index === props.topWordIndex) {
      lettersRevealed = props.topLetterIndex;
    } else if (index === props.botWordIndex) {
      lettersRevealed = props.botLetterIndex;
    }

    return <Link word={word} active={props.activeIndex === index} lettersRevealed={lettersRevealed}></Link>
  });

  return (
    <div className="Chain">
      {links}
    </div>
  );
}

export default Chain;