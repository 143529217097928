import React from 'react';
import './Score.css';

interface ScoreProps {
  label: string;
  active: boolean;
  score: number;
}

class Score extends React.Component<ScoreProps> {
  render() {
    const classNames = [];
    classNames.push("Score");
    if (this.props.active) {
      classNames.push("active");
    }

    return (
      <div className={classNames.join(" ")}>
        <h3>{this.props.label}</h3>
        <div>{this.props.score}</div>
      </div>
    );
  }
}

export default Score;