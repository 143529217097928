import './Setup.css'
import React from 'react';

interface SetupProps {
  rounds: string[][];
  startGame: (rounds: string[][], teams: string[][]) => void
}
interface SetupState {
  rounds: string[][];
  teams: string[][];
}

class Setup extends React.Component<SetupProps, SetupState> {

  constructor(props: SetupProps) {
    super(props);

    this.state = {
      rounds: props.rounds,
      teams: [["Player 1", "Player 2"],["Player 3","Player 4"]]
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeRound = this.onChangeRound.bind(this);
  }
  

  onSubmit(event: React.FormEvent) {
    this.props.startGame(this.state.rounds, this.state.teams);
  }

  onChangeRound(event: any, round: number) {
    const nextState = this.state as SetupState;
    const value = event.target.value as string;
    nextState.rounds[round] = value.split(',');
    this.setState(nextState);
  }

  onChangeTeam(event: any, team: number) {
    const nextState = this.state as SetupState;
    const value = event.target.value as string;
    nextState.teams[team] = value.split(',');
    this.setState(nextState);
  }

  render() {
    const chains = this.state.rounds.map((round, index) => {
      const chain = round.join(',');
      return (
        <div className='round-input'>
          <div className='round-input-label'>Round {index + 1}</div>
          <input className='round-input-box' id={"round-" + (index + 1)} type="text" placeholder='Comma,Separated,Chain' value={chain} onChange={evt => this.onChangeRound(evt, index)}></input>
        </div>
      );
    });

    const teams = this.state.teams.map((teamArr, index) => {
      const team = teamArr.join(',');
      return (
        <div className='team-input'>
          <div className='team-input-label'>Team {index + 1}</div>
          <input className='team-input-box' id={"team-" + (index + 1)} type="text" placeholder='Comma,Separated,Chain' value={team} onChange={evt => this.onChangeTeam(evt, index)}></input>
        </div>
      );
    });

    return (
      <fieldset className="Setup">
        <h3>Chains</h3>
        {chains}

        <h3>Team</h3>
        {teams}
        <button onClick={this.onSubmit}>Play</button>
      </fieldset>
    );
  }
}

export default Setup;