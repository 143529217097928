import React from 'react';
import './Game.css';
import Chain from './Chain';
import Score from './Score';

interface GameProps {
  rounds: string[][];
  teams: string[][];
}

interface GameState {
  turnTeam: 0 | 1;
  player: number[];
  scores: number[];
  wager?: number;
  turnPhase: 'wager' | 'selection' | 'guess' | 'next' | 'done';
  turnWord: 'top' | 'bottom';
  roundStartTeam: 0 | 1;
  round: number;
  chainState: {
    topWordIndex: number;
    topLetterIndex: number;
    botWordIndex: number;
    botLetterIndex: number;
  }
}

class Game extends React.Component<GameProps, GameState> {

  constructor(props: GameProps) {
    super(props);
    this.state = this.getInitialRoundState(0, 0, [0, 0], [0, 0]);
    this.nextRound = this.nextRound.bind(this);
    this.below = this.below.bind(this);
    this.above = this.above.bind(this);
    this.correct = this.correct.bind(this);
    this.wrong = this.wrong.bind(this);
  }

  private getInitialRoundState(round: number, team: 0 | 1, scores: number[], player: number[]): GameState {
    return {
      turnPhase: round === this.props.rounds.length - 1 ? 'wager' : 'selection',
      turnTeam: team,
      roundStartTeam: team,
      turnWord: 'top',
      round,
      scores,
      player,
      chainState: {
        topWordIndex: 1,
        topLetterIndex: 0,
        botWordIndex: this.props.rounds[round].length - 2,
        botLetterIndex: 0
      }
    }
  }

  nextRound() {
    const team0Score = this.state.scores[0];
    const team1Score = this.state.scores[1];
    const nextTeam = team0Score === team1Score ? (this.state.roundStartTeam === 0 ? 1 : 0) : (team0Score < team1Score ? 0 : 1);
    const nextState = this.getInitialRoundState(this.state.round + 1, nextTeam, this.state.scores, this.state.player);
    if (this.state.round < this.props.rounds.length - 1) {
      this.setState(nextState);
    } else {
      nextState.turnPhase = 'done';
      this.setState(nextState);
    }
  }

  wager(value: number) {
    const nextState = this.state as GameState;
    nextState.wager = value;

    // Skip selection
    if (nextState.chainState.topWordIndex === nextState.chainState.botWordIndex) {
      const curWordLength = this.props.rounds[nextState.round][nextState.chainState.botWordIndex].length;
      const maxIndex = Math.min(Math.max(nextState.chainState.topLetterIndex, nextState.chainState.botLetterIndex), curWordLength - 2);
      nextState.chainState.topLetterIndex = maxIndex + 1;
      nextState.chainState.botLetterIndex = maxIndex + 1;
      nextState.turnWord = 'top';
      nextState.turnPhase = 'guess';
    }
    // Go to selection
    else {
      nextState.turnPhase = 'selection';
    }

    this.setState(nextState);
  }

  below() {
    const curRoundState = this.state.chainState;
    const curRound = this.state.round;
    const nextState = this.state as GameState;
    const topWordLength = this.props.rounds[curRound][curRoundState.topWordIndex].length;
    if (curRoundState.topLetterIndex < topWordLength - 1) {
      nextState.chainState.topLetterIndex += 1;
    }

    nextState.turnWord = 'top';
    nextState.turnPhase = 'guess';
    this.setState(nextState);
  }

  above() {
    const curRoundState = this.state.chainState;
    const curRound = this.state.round;
    const nextState = this.state as GameState;
    const botWordLength = this.props.rounds[curRound][curRoundState.botWordIndex].length;
    if (curRoundState.botLetterIndex < botWordLength - 1) {
      nextState.chainState.botLetterIndex += 1;
    }

    nextState.turnWord = 'bottom';
    nextState.turnPhase = 'guess';
    this.setState(nextState);
  }

  correct() {
    const nextState = this.state as GameState;
    nextState.scores[nextState.turnTeam] += nextState.wager !== undefined ? nextState.wager : (nextState.round + 1) * 100;
    nextState.player[nextState.turnTeam] += 1;
    nextState.player[nextState.turnTeam] %= this.props.teams[nextState.turnTeam].length;

    if (nextState.turnWord === 'top') {
      nextState.chainState.topWordIndex += 1;
      nextState.chainState.topLetterIndex = 0;
    }

    if (nextState.turnWord === 'bottom') {
      nextState.chainState.botWordIndex -= 1;
      nextState.chainState.botLetterIndex = 0;
    }

    // End of round
    if (nextState.chainState.topWordIndex > nextState.chainState.botWordIndex) {
      nextState.turnPhase = nextState.round + 1 === this.props.rounds.length ? 'done' : 'next';
    }
    // Go to wager directly
    else if (nextState.round === this.props.rounds.length - 1) {
      nextState.turnPhase = 'wager';
    }
    // Skip selection
    else if (nextState.chainState.topWordIndex === nextState.chainState.botWordIndex) {
      const curWordLength = this.props.rounds[nextState.round][nextState.chainState.botWordIndex].length;
      const maxIndex = Math.min(Math.max(nextState.chainState.topLetterIndex, nextState.chainState.botLetterIndex), curWordLength - 2);
      nextState.chainState.topLetterIndex = maxIndex + 1;
      nextState.chainState.botLetterIndex = maxIndex + 1;
      nextState.turnWord = 'top';
      nextState.turnPhase = 'guess';
    }
    // Go to selection
    else {
      nextState.turnPhase = 'selection';
    }

    this.setState(nextState);
  }

  wrong() {
    const nextState = this.state as GameState;
    if (nextState.wager !== undefined) {
      nextState.scores[nextState.turnTeam] -= nextState.wager;
    }

    nextState.player[nextState.turnTeam] += 1;
    nextState.player[nextState.turnTeam] %= this.props.teams[nextState.turnTeam].length;
    nextState.turnTeam = nextState.turnTeam === 0 ? 1 : 0;

    // Go directly to wager
    if (nextState.round === this.props.rounds.length - 1) {
      nextState.turnPhase = 'wager';
    }
    // Skip selection
    else if (nextState.chainState.topWordIndex === nextState.chainState.botWordIndex) {
      nextState.turnPhase = 'guess';
      nextState.turnWord = 'top';
      const curWordLength = this.props.rounds[nextState.round][nextState.chainState.botWordIndex].length;
      if (nextState.chainState.botLetterIndex < curWordLength - 1) {
        nextState.chainState.botLetterIndex += 1;
        nextState.chainState.topLetterIndex += 1;
      }
    }
    // Go to selection
    else {
      nextState.turnPhase = 'selection';
    }

    this.setState(nextState);
  }

  render() {
    const chainState = this.state.chainState;

    const wagerButtons = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000].filter(amt => chainState.botWordIndex === chainState.topWordIndex ? true : amt <= 500).map(amt => {
      return (<button className='Game-control-wager' onClick={() => this.wager(amt)}>{amt}</button>);
    });

    const playerName = this.state.turnPhase !== 'next' && this.state.turnPhase !== 'done' ? this.props.teams[this.state.turnTeam][this.state.player[this.state.turnTeam]] : '---';
    const activeIndex = this.state.turnPhase === 'guess' ? (this.state.turnWord === 'top' ? chainState.topWordIndex : chainState.botWordIndex) : undefined;
    return (
      <div className="Game">
        <div className="Score-bar">
          <Score label="Team 1" active={this.state.turnTeam === 0} score={this.state.scores[0]} />
          <div className="Status">
            <h2>Round {this.state.round + 1}</h2>
            <div>{playerName}</div>

          </div>
          <Score label="Team 2" active={this.state.turnTeam === 1} score={this.state.scores[1]} />
        </div>
        <div className="Game-chain">
          <Chain
            words={this.props.rounds[this.state.round]}
            topWordIndex={chainState.topWordIndex}
            topLetterIndex={chainState.topLetterIndex}
            botWordIndex={chainState.botWordIndex}
            botLetterIndex={chainState.botLetterIndex}
            activeIndex={activeIndex}
          />
        </div>
        <div className="Game-controls">
          {this.state.turnPhase === 'wager' &&
            <div className='Wager-buttons'>
              {wagerButtons}
            </div>
          }

          {this.state.turnPhase === 'selection' &&
            <button className='Game-control-below' onClick={this.below}>Below</button>
          }

          {this.state.turnPhase === 'selection' &&
            <button className='Game-control-above' onClick={this.above}>Above</button>
          }

          {this.state.turnPhase === 'guess' &&
            <button className='Game-control-correct' onClick={this.correct}>Correct</button>
          }

          {this.state.turnPhase === 'guess' &&
            <button className='Game-control-wrong' onClick={this.wrong}>Wrong</button>
          }

          {this.state.turnPhase === 'next' &&
            <button className='Game-control-next-round' onClick={this.nextRound}>Next Round</button>
          }
        </div>
      </div>
    );
  }
}

export default Game;
